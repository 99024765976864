<template>

  <cartoLeaflet v-if="!loading_data" :dataCarto="cartoData" />


  
</template>

<script>
import  cartoLeaflet from "@/components/carto-leaflet/index.vue";

export default {
  name: "carto",
  data() {
    return {
      loading_data : true,
      cartoData: {},
      

    };
  },
  components:{cartoLeaflet},
  created(){
    // Simple GET request using axios
    const BASE_URL = process.env.VUE_APP_BASEURL;
    this.axios.get(BASE_URL+"/sites")
      .then(response => {
        
        var _response = response.data.data
        console.log(_response)
        this.cartoData = _response
        
        //return this.carto=response.data;
        this.loading_data = false;
      });
  }
};

</script>
