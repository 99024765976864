
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import { Icon } from 'leaflet';

import customMarkerForest from '../../assets/img/carto/marker_forest.svg'
import customMarkerUrban from '../../assets/img/carto/marker_urban.svg'
import customMarkerMoutain from '../../assets/img/carto/marker_montagne.svg'
import customMarkerAgricultural from '../../assets/img/carto/marker_agricultural.svg'



delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  name: 'cartoLeaflet',
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    dataCarto: {},
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: [43.147971, 1.969413],
      zoom: 4,

      villes: L.layerGroup(),


      forestIcons: L.icon({
        iconUrl: customMarkerForest,

        iconSize: [38, 95],
        iconAnchor: [19, 48],
        popupAnchor: [0, -30],
      }),

      urbanIcons: L.icon({
        iconUrl: customMarkerUrban,

        iconSize: [38, 95],
        iconAnchor: [19, 48],
        popupAnchor: [0, -30],
      }),

      moutainIcons: L.icon({
        iconUrl: customMarkerMoutain,

        iconSize: [38, 95],
        iconAnchor: [19, 48],
        popupAnchor: [0, -30],
      }),

      agriculturalIcons: L.icon({
        iconUrl: customMarkerAgricultural,

        iconSize: [38, 95],
        iconAnchor: [19, 48],
        popupAnchor: [0, -30],
      }),


    }
  },
  computed: {

  },
  mounted() {
    this.prepare_data_for_leaflet(this.dataCarto)
  },
  created() {



  },
  methods: {
    prepare_data_for_leaflet(data_leaflet) {

      const mapDiv = L.map("mapContainer").setView(this.center, 8);

      var osm = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright%22%3EOpenStreetMap</a> contributors'
      }).addTo(mapDiv);

      var sat = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
      });

      var baseLayers = {
        "open street map": osm,
        "satellite": sat
      };
      baseLayers

      var ensemble = [];
      var ensemble_nom = [];
      var ensemble_typo = []
      for (var lieu of data_leaflet) {
        var coordonne = lieu.location_coordinates
        var transfo_1 = coordonne.replace("[", '');
        var transfo_2 = transfo_1.replace(']', '');
        var coord_array = transfo_2.split(',')



        var good_coord = []
        //code pour choper les nom ici
        var name = lieu.location_name;
        ensemble_nom.push(name)

        //code pour chope les type de site
        var typo = lieu.site_typologie;
        ensemble_typo.push(typo);

        typo
        ensemble_typo

        for (let coord of coord_array) {
          good_coord.push(parseFloat(coord))


        }

        ensemble.push(good_coord)
      }
      //en dehor de la boucle
     // var villes = L.layerGroup();
      var montagnes = L.layerGroup();
      var forets = L.layerGroup();
      var agricoles = L.layerGroup();




      for (var i = 0; i < ensemble.length; i++) {
        var customPopup = "<div style='margin-top:0.5em'><a class='bg-blue-500 hover:bg-blue-400 rounded py-0.5 px-1' href='#/sites' style='color:white'>Acceder aux données du site</a></div>";
        customPopup

        if (ensemble_typo[i] == "foret") {

          L.marker((ensemble[i]), { icon: this.forestIcons }).addTo(mapDiv).bindPopup(ensemble_nom[i] + "</br>" + "typologie du site : " + ensemble_typo[i] + "</br>" + customPopup).addTo(forets)
        } else if (ensemble_typo[i] == "urbain") {
          L.marker((ensemble[i]), { icon: this.urbanIcons }).addTo(mapDiv).bindPopup(ensemble_nom[i] + "</br>" + "typologie du site : " + ensemble_typo[i] + "</br>" + customPopup).addTo(this.villes);

        } else if (ensemble_typo[i] == "agricole") {
          L.marker((ensemble[i]), { icon: this.agriculturalIcons }).addTo(mapDiv).bindPopup(ensemble_nom[i] + "</br>" + "typologie du site : " + ensemble_typo[i] + "</br>" + customPopup).addTo(agricoles);

        } else if (ensemble_typo[i] == "montagne") {
          L.marker((ensemble[i]), { icon: this.moutainIcons }).addTo(mapDiv).bindPopup(ensemble_nom[i] + "</br>" + "typologie du site : " + ensemble_typo[i] + "</br>" + customPopup).addTo(montagnes);

        } else {
          L.marker(ensemble[i]).addTo(mapDiv).bindPopup(ensemble_nom[i] + "</br>" + "type de site : " + ensemble_typo[i] + "</br>" + customPopup);

        }


      }


      var overlays = {
        "Typologie urbaine": this.villes,
        "Typologie montagnarde": montagnes,
        "Typologie agricole": agricoles,
        "Typologie forestière": forets
      };

      L.control.layers(baseLayers, overlays).addTo(mapDiv);




    }
    , zoomUpdated(zoom) {
      this.zoom = zoom;

    },
    centerUpdated(center) {
      this.center = center;
    }
  }
}



