<section class="carto-leaflet">


  <h1 class="text-center text-2xl text-black font-semibold mt-3">Carte des sites du projet Econect</h1>


  <div id="container" class='flex items-center justify-center px-10 py-10'>
    <div id="mapContainer" class="border border-black shadow-2xl"></div>
  </div>

  <div>

   <!-- <div id="mapdiv"></div>-->

  </div>

</section>